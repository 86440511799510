<template>
    <vx-card :title="'Detail Simple Stock Transfer Order Delivery Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO Doc. Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO DO Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="codeDO" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    :value="this.selectedSrcWarehouse"
                    :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    :value="this.selectedDestWarehouse"
                    :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.deliveryDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea :readonly="true" v-model="notes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="50%" style="vertical-align: middle;" rowspan="2">SKU</th>
                            <th width="15%" style="text-align: center;" colspan="2">STO Qty</th>
                            <th width="5%" style="text-align: center;"></th>
                            <th width="25%" style="text-align: center;" colspan="3">DO Qty</th>
                        </tr>
                        <tr>
                            <th width="10%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="5%"></th>
                            <th width="10%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="10%">Batch #</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" 
                                    style="padding: 5px"
                                    :rowspan="skuLines[index].do_lines.length">
                                    <vs-input 
                                        :value="skuLine.selected_sku.label"
                                        :readonly="true" 
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" 
                                    style="padding: 5px"
                                    :rowspan="skuLines[index].do_lines.length">
                                    <vs-input 
                                        :value="skuLine.selected_hu" 
                                        :readonly="true" 
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].do_lines.length">
                                    <vs-input 
                                        :value="skuLine.qty" 
                                        :readonly="true" 
                                        class="w-full"></vs-input>
                                </td>
                                <td :rowspan="skuLines[index].do_lines.length"></td>

                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="text"
                                        :readonly="true" 
                                        v-model="skuLines[index].do_lines[0].selected_hu" 
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="number" 
                                        :readonly="true" 
                                        v-model="skuLines[index].do_lines[0].qty"
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="text" 
                                        :readonly="true" 
                                        v-model="skuLines[index].do_lines[0].batch"
                                        class="w-full"></vs-input>
                                </td>
                            </tr>

                            <template v-for="(doLine, indexDoLine) in skuLines[index].do_lines">
                                <tr style="padding-bottom: 5px" :key="index + '-' + indexDoLine" v-if="indexDoLine > 0">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input 
                                            type="text"
                                            :readonly="true" 
                                            v-model="skuLines[index].do_lines[indexDoLine].selected_hu" 
                                            class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input 
                                            type="number" 
                                            :readonly="true" 
                                            v-model="skuLines[index].do_lines[indexDoLine].qty"
                                            class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input 
                                            type="text" 
                                            :readonly="true" 
                                            v-model="skuLines[index].do_lines[indexDoLine].batch"
                                            class="w-full"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        Warehouse,
        Date,
        vSelect
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            codeDO: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            deliveryDate: null,
            eta: null,
            notes: null,
            skuLines: [
                {
                    stock_relocation_plan_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    do_lines: [{
                        selected_hu: null,
                        qty: 0,
                        batch: "",
                    }]
                }
            ],

            file: null,
            fileUrl: '',
        }
    },
    mounted() {
        var now = moment()

        this.deliveryDate = now.toDate()

        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.$store.state.outbound.simpleStoDo.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.code = resp.data.code
                        this.codeDO = resp.data.code_do
                        this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                        this.selectedDestWarehouse = resp.data.selected_dest_warehouse.label
                        this.deliveryDate = moment(resp.data.delivery_date).toDate()
                        this.eta = moment(resp.data.eta).toDate()
                        this.notes = resp.data.notes
                        this.skuLines = resp.data.sku_lines

                        this.fileUrl = resp.data.file_url
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.outbound.simpleStoDo.baseRouterName,
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {

    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD MMM YYYY');
            };
        },
    }
}
</script>